import { IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import About from "../Asset/aboutus.png";
import { useContext, useEffect } from 'react';
import IndicatorContext from '../data/indicator-context';

const AboutUs: React.FC = () => {
    const indicatorCtx = useContext(IndicatorContext);
    const listIndicators = indicatorCtx.indicators;
    useEffect(() => {
        console.log(listIndicators);
    }, [listIndicators])
    return (
        <IonPage id="main-content">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start" color='white'>
                        <IonMenuButton id="aboutus"></IonMenuButton>
                    </IonButtons>

                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <img src={About} alt="About" />
            </IonContent>
        </IonPage>
    );
};

export default AboutUs;

import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterOutlet, IonTitle, IonToolbar, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import IndicatorContextProvider from './data/IndicatorContextProvider';
import { list, settings, warning } from "ionicons/icons";
import Menu from './pages/Menu';

setupIonicReact();

const App: React.FC = () => {
  return (
    <IonApp>
      <IndicatorContextProvider>
        <IonReactRouter>
          <IonRouterOutlet id='app'>
            <Route path="/app" component={Menu} />
            <Redirect exact from='/' to="/app" />
          </IonRouterOutlet>
        </IonReactRouter>
      </IndicatorContextProvider>
    </IonApp>
  );
};

export default App;

import { IonButton, IonModal } from "@ionic/react";
import { IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton, IonPage, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import IndicatorContext, { Indicator } from "../data/indicator-context";
import { Controller, set, useForm } from "react-hook-form";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import "./Test.css";
import RecommendationContext, { Recommendation } from "../data/recommendation-context";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const Test: React.FC = () => {
  var cK1 = 0;
  var cK2 = 0;
  var cK3 = 0;
  var cK4 = 0;
  var cK5 = 0;
  var cK6 = 0;
  var cK7 = 0;
  var cK8 = 0;
  const indicatorCtx = useContext(IndicatorContext);
  const recommendationCtx = useContext(RecommendationContext);
  const [result1, setResult1] = useState<String>();
  const [result2, setResult2] = useState<String>();
  const [result3, setResult3] = useState<String>();
  const [result4, setResult4] = useState<String>();
  const [result5, setResult5] = useState<String>();
  const [result6, setResult6] = useState<String>();
  const [result7, setResult7] = useState<String>();
  const [result8, setResult8] = useState<String>();
  const [listIndicators, setListIndicators] = useState<Indicator[]>();
  const [listRecommendation, setListRecommendation] = useState<Recommendation[]>();
  useEffect(() => {
    setListIndicators(indicatorCtx.indicators);
  }, [indicatorCtx.indicators]);
  useEffect(() => {
    setListRecommendation(recommendationCtx.recommendations);
  }, [recommendationCtx.recommendations]);
  const { handleSubmit } = useForm();
  const submitt = (data: any) => {
    console.log(data);
    const inputs = document.querySelectorAll(".inputRadio");
    inputs.forEach(input => {
      console.log(input);
    })
    var j;
    var result1, result2, result3, result4, result5, result6, result7, result8;
    for (j = 0; j < indicatorCtx.indicators.length; j++) {
      if (indicatorCtx.indicators[j].type === "K1") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK1++;
        }
      } else if (indicatorCtx.indicators[j].type === "K2") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK2++;
        }
      } else if (indicatorCtx.indicators[j].type === "K3") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK3++;
        }
      } else if (indicatorCtx.indicators[j].type === "K4") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK4++;
        }
      } else if (indicatorCtx.indicators[j].type === "K5") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK5++;
        }
      } else if (indicatorCtx.indicators[j].type === "K6") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK6++;
        }
      } else if (indicatorCtx.indicators[j].type === "K7") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK7++;
        }
      } else if (indicatorCtx.indicators[j].type === "K8") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK8++;
        }
      }
    }
    result1 = ((cK1 / 11) * 100).toFixed(2);
    result2 = ((cK2 / 11) * 100).toFixed(2);
    result3 = ((cK3 / 11) * 100).toFixed(2);
    result4 = ((cK4 / 11) * 100).toFixed(2);
    result5 = ((cK5 / 11) * 100).toFixed(2);
    result6 = ((cK6 / 11) * 100).toFixed(2);
    result7 = ((cK7 / 11) * 100).toFixed(2);
    result8 = ((cK8 / 11) * 100).toFixed(2);
    // console.log(indicatorCtx.indicators.filter((nilai) => nilai.value > 0).map((filteredNilai) => <li>{filteredNilai.value}</li>));
    // console.log(indicatorCtx.indicators);
    setResult1(result1);
    setResult2(result2);
    setResult3(result3);
    setResult4(result4);
    setResult5(result5);
    setResult6(result6);
    setResult7(result7);
    setResult8(result8);
  };

  const dataSource = {
    data: [
      { label: "Verbal", value: result1 },
      { label: "Logis", value: result2 },
      { label: "Visual", value: result3 },
      { label: "Kinestatik", value: result4 },
      { label: "Musikal", value: result5 },
      { label: "Interpersonal", value: result6 },
      { label: "Intrapersonal", value: result7 },
      { label: "Naturalis", value: result8 },
    ],
    chart: {
      caption: "Hasil Tes Intelligence",
      subCaption: "",
      xAxisName: "Tipe Kecerdasan",
      yAxisName: "Tingkat Kecerdasan",
      numberSuffix: "%",
    },
  };
  const chartConfigs = {
    type: "column2d",
    width: 600,
    height: 400,
    dataFormat: "json",
    dataSource: dataSource,
  };

  const setValue = (value: 0 | 1, i: Indicator) => {
    indicatorCtx.setValue(i, value);
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start" color="white">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Intelligence Test</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="background ion-padding" fullscreen>
        <IonModal isOpen={showModal}>
          {/* <li>Kecerdasan 1 = {result1}% </li>
          <li>Kecerdasan 2 = {result2}%</li>
          <li>Kecerdasan 3 = {result3}%</li>
          <li>Kecerdasan 4 = {result4}%</li>
          <li>Kecerdasan 5 = {result5}%</li>
          <li>Kecerdasan 6 = {result6}%</li>
          <li>Kecerdasan 7 = {result7}%</li>
          <li>Kecerdasan 8 = {result8}%</li> */}
          <IonItem style={{ marginTop: "40px", marginLeft: "-13px" }}>
            <ReactFC {...chartConfigs} />
          </IonItem>

          {listRecommendation?.map((i) => (
            <IonList key={i.id}>
              <IonLabel>{i.job}</IonLabel>
            </IonList>
          ))}
          <IonButton style={{ marginTop: "60px" }} onClick={() => setShowModal(false)}>
            Close
          </IonButton>
        </IonModal>
        <form className="glassmorphism ion-padding ion-padding-horizontal ion-margin-horizontal" onSubmit={handleSubmit(submitt)}>
          {listIndicators?.map((i, idx) => {
            const num = idx + 1;
            if (idx % 11 !== 0) {
              return (
                <IonList key={i.id} class="bg-trans">
                  <IonGrid className="ion-margin-horizontal">
                    <IonRow>
                      <IonLabel className="ion-text-start ion-text-lg-wrap">{idx + 1}. {i.question}</IonLabel>
                    </IonRow>
                    <IonRow >
                      <IonRadioGroup onIonChange={(e) => setValue(e.detail.value, i)} key={i.type} id={i.id} className="ion-no-padding inputRadio">
                        <IonItem color="trans" className="ion-text-start" class="bg-trans">
                          <IonLabel>Ya</IonLabel>
                          <IonRadio slot="start" value={1} color="success"></IonRadio>
                        </IonItem>
                        <IonItem color="trans" className="ion-text-start" class="bg-trans">
                          <IonLabel>Tidak</IonLabel>
                          <IonRadio slot="start" value={0} color="danger"></IonRadio>
                        </IonItem>
                      </IonRadioGroup>
                    </IonRow>
                  </IonGrid>
                </IonList>
              )
            } else {
              var type = "";
              if (i.type === "K1") {
                type = "Kecerdasan Verbal/Lingustik";
              } else if (i.type === "K2") {
                type = "Kecerdasan Logis";
              } else if (i.type === "K3") {
                type = "Kecerdasan Visual";
              } else if (i.type === "K4") {
                type = "Kecerdasan Kinestik";
              } else if (i.type === "K5") {
                type = "Kecerdasan Musikal";
              } else if (i.type === "K6") {
                type = "Kecerdasan Interpersonal";
              } else if (i.type === "K7") {
                type = "Kecerdasan Intrapersonal";
              } else if (i.type === "K8") {
                type = "Kecerdasan Naturalis";
              }
              return (
                <IonList key={i.id} class="bg-trans">
                  <IonHeader color="primary" class="toolbar-title" className="ion-margin-bottom ion-padding">
                    <IonTitle color="danger" className="ion-text-lg-wrap"><b className="ion-margin-vertical ion-text-wrap">{type}</b></IonTitle>
                  </IonHeader>
                  <IonGrid className="ion-margin-horizontal">
                    <IonRow>
                      <IonLabel className="ion-text-start ion-text-lg-wrap">{idx + 1}. {i.question}</IonLabel>
                    </IonRow>
                    <IonRow >
                      <IonRadioGroup onIonChange={(e) => setValue(e.detail.value, i)} key={i.type} id={i.id} className="ion-no-padding inputRadio" >
                        <IonItem color="trans" className="ion-text-start" class="bg-trans">
                          <IonLabel>Ya</IonLabel>
                          <IonRadio slot="start" value={1} color="success"></IonRadio>
                        </IonItem>
                        <IonItem color="trans" className="ion-text-start" class="bg-trans">
                          <IonLabel>Tidak</IonLabel>
                          <IonRadio slot="start" value={0} color="danger"></IonRadio>
                        </IonItem>
                      </IonRadioGroup>
                    </IonRow>
                  </IonGrid>
                </IonList>
              )
            }

          })}
          <IonButton onClick={() => setShowModal(true)} className="ion-margin-top" type="submit" expand="block">
            Submit
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Test;
// function useForm(): { control: any; handleSubmit: any } {
//   throw new Error("Function not implemented.");
// }
